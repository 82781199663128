'use client';
import { signIn } from 'next-auth/react';
import { FcGoogle as Google } from 'react-icons/fc';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

import { cn } from '@/util';

export default function LoginForm({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={cn('flex flex-col gap-6', className)} {...props}>
      <Card>
        <CardHeader>
          <CardTitle className='text-2xl'>SnackLang Admin Login</CardTitle>
          <CardDescription>Authorised access only (for now)</CardDescription>
        </CardHeader>
        <CardContent>
          <Button
            onClick={() => signIn('google', { redirectTo: '/admin' })}
            variant='outline'
            className='w-full'
          >
            <Google className='mr-2 h-4 w-4' />
            Login with Google
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
